<template>
  <div>
    <div class="question">
      <div v-if="isNotUniversita" class="number-row">
        <div class="number-row-question">
          <span class="question-title">Totale addetti/e, nell'anno solare {{ referenceYear }}</span>
        </div>
        <div class="number-row-answer">
          <div class="number-row-answer-male">
            <label>
              <span>M</span>
              <input class="numericvalue" type="number" :value="surveyData.company_nb_workers_M" disabled>
            </label>
          </div>
          <div class="number-row-answer-female">
            <label>
              <span>F</span>
              <input class="numericvalue" type="number" :value="surveyData.company_nb_workers_F" disabled>
            </label>
          </div>
        </div>
      </div>

      <div v-if="isUniversita" class="number-row">
        <div class="number-row-question">
          <span class="question-title" v-tooltip.hover="'Ci si riferisce al numero di persone e non al numero di contratti'">
              Totale personale docente, nell'anno solare {{ referenceYear }}
          </span>
        </div>
        <div class="number-row-answer">
          <div class="number-row-answer-male">
            <label>
              <span>M</span>
              <input class="numericvalue" type="number" :min="0" :value="surveyData.company_nb_teachers_M" disabled>
            </label>
          </div>
          <div class="number-row-answer-female">
            <label>
              <span>F</span>
              <input class="numericvalue" type="number" :min="0" :value="surveyData.company_nb_teachers_F" disabled>
            </label>
          </div>
        </div>
      </div>

      <div v-if="isUniversita" class="number-row">
        <div class="number-row-question">
          <span class="question-title" v-tooltip.hover="'Ci si riferisce al numero di persone e non al numero di contratti'">
              Totale personale tecnico-amministrativo, nell'anno solare {{ referenceYear }}
          </span>
        </div>
        <div class="number-row-answer">
          <div class="number-row-answer-male">
            <label>
              <span>M</span>
              <input class="numericvalue" type="number" :min="0" :value="surveyData.company_nb_technical_M" disabled>
            </label>
          </div>
          <div class="number-row-answer-female">
            <label>
              <span>F</span>
              <input class="numericvalue" type="number" :min="0" :value="surveyData.company_nb_technical_F" disabled>
            </label>
          </div>
        </div>
      </div>

      <div v-if="isUniversita" class="number-row">
        <div class="number-row-question">
          <span class="question-title">
              Totale studenti e studentesse, nell'anno accademico {{ referenceYear }}
          </span>
        </div>
        <div class="number-row-answer">
          <div class="number-row-answer-male">
            <label>
              <span>M</span>
              <input class="numericvalue" type="number" :min="0" :value="surveyData.company_nb_students_M" disabled>
            </label>
          </div>
          <div class="number-row-answer-female">
            <label>
              <span>F</span>
              <input class="numericvalue" type="number" :min="0" :value="surveyData.company_nb_students_F" disabled>
            </label>
          </div>
        </div>
      </div>

      <template v-if="isUniversita">
        <button v-if="canFillUniData" class="mt-4 btn btn-outline-secondary" @click="fillUniData">Recupera dati automaticamente</button>
      </template>
    </div>

    <template v-if="isUniversita">
      <h3>Personale docente e ricercatore anno solare {{ referenceYear }}</h3>

      <div class="question">
        <table>
          <thead>
          <tr style="vertical-align: top">
            <th style="width: calc(100% - 500px)">Ruolo</th>
            <th style="width: 250px">Distribuzione</th>
            <th style="width: 250px">Età media</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="role in [
            { name: 'PO', id: 'grade_a', id_age: 'po'},
            { name: 'PA', id: 'grade_b', id_age: 'pa' },
            { name: 'RU', id: 'ru', id_age: 'ru' },
            { name: 'RTDb', id: 'rtdb', id_age: 'rtdb' },
            { name: 'RTDa', id: 'rtda', id_age: 'rtda' },
            { name: 'AR', id: 'ar', id_age: 'ar' },
          ]" :key="role.id" style="vertical-align: top">
            <th role="rowheader">{{ role.name }}</th>

            <td><!-- Distribuzione -->
              <div class="d-flex">
                <label>
                  <span>M</span>
                  <input class="numericvalue" type="number" :step="1" :min="0" :max="surveyData.company_nb_teachers_M" :value="surveyData['company_nb_teachers__' + role.id + '__M']" @input="setTextAsInteger('company_nb_teachers__' + role.id + '__M', $event)">
                </label>
                <label>
                  <span>F</span>
                  <input class="numericvalue" type="number" :step="1" :min="0" :max="surveyData.company_nb_teachers_F" :value="surveyData['company_nb_teachers__' + role.id + '__F']" @input="setTextAsInteger('company_nb_teachers__' + role.id + '__F', $event)">
                </label>
              </div>
            </td>

            <td><!-- Età media -->
              <div class="d-flex">
                <label>
                  <span>M</span>
                  <input class="numericvalue" type="number" :step=".1" :min="0" :value="surveyData['company_university_avg_age__' + role.id_age + '__M']" @input="setTextAsFloat('company_university_avg_age__' + role.id_age + '__M', $event)">
                </label>

                <label>
                  <span>F</span>
                  <input class="numericvalue" type="number" :step=".1" :min="0" :value="surveyData['company_university_avg_age__' + role.id_age + '__F']" @input="setTextAsFloat('company_university_avg_age__' + role.id_age + '__F', $event)">
                </label>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      <div class="question">
        <span class="question-title">Distribuzione per genere, ruolo ed età</span>

        <table>
          <thead>
          <tr style="vertical-align: top">
            <th style="width: calc(100% - 1000px)">Classe di età</th>
            <th style="width: 250px">PO</th>
            <th style="width: 250px">PA</th>
            <th style="width: 250px">RTDb + RTDa + RU</th>
            <th style="width: 250px">AR</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="age in [
            { id: 'lt35', name: '&lt;35 anni' },
            { id: '35-44', name: '35-44 anni' },
            { id: '45-54', name: '45-54 anni' },
            { id: 'gt54', name: '&gt;54 anni' },
          ]" :key="age.id" style="vertical-align: top">
            <th role="rowheader">
              {{ age.name }}
            </th>

            <td v-for="role in [
              { id: 'grade_a', name: 'PO'},
              { id: 'grade_b', name: 'PA'},
              { id: 'grade_c', name: 'RTDb + RTDa + RU'},
              { id: 'grade_d', name: 'AR'},
            ]" :key="role.id">
              <div class="d-flex">
                <label>
                  <span>M</span>
                  <input class="numericvalue" type="number" :step="1" :min="0" :max="surveyData.company_nb_teachers_M" :value="surveyData['company_nb_teachers__' + age.id + '__' + role.id + '__M']" @input="setTextAsInteger('company_nb_teachers__' + age.id + '__' + role.id + '__M', $event)">
                </label>
                <label>
                  <span>F</span>
                  <input class="numericvalue" type="number" :step="1" :min="0" :max="surveyData.company_nb_teachers_F" :value="surveyData['company_nb_teachers__' + age.id + '__' + role.id + '__F']" @input="setTextAsInteger('company_nb_teachers__' + age.id + '__' + role.id + '__F', $event)">
                </label>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      <div class="question">
        <span class="question-title">Distribuzione per genere, area CUN e ruolo</span>

        <table>
          <thead>
          <tr style="vertical-align: top">
            <th style="width: calc(100% - 750px)">Area CUN</th>
            <th style="width: 250px">PO</th>
            <th style="width: 250px">PA</th>
            <th style="width: 250px">RTDb</th>
            <th style="width: 250px">RTDa</th>
            <th style="width: 250px">RU</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="cunArea in cunAreas" :key="cunArea.id" style="vertical-align: top">
            <th role="rowheader">{{ cunArea.name }}</th>

            <td v-for="role in [
              { id: 'grade_a', name: 'PO'},
              { id: 'grade_b', name: 'PA'},
              { id: 'rtdb', name: 'RTDb'},
              { id: 'rtda', name: 'RTDa'},
              { id: 'ru', name: 'RU'},
            ]" :key="'cun_' + cunArea.id + '_role_' + role.id">
              <div class="d-flex">
                <label>
                  <span>M</span>
                  <input class="numericvalue" type="number" :step="1" :min="0" :max="surveyData.company_nb_teachers_M" :value="surveyData['company_nb_teachers__' + cunArea.id + '__' + role.id + '__M']" @input="setTextAsInteger('company_nb_teachers__' + cunArea.id + '__' + role.id + '__M', $event)">
                </label>

                <label>
                  <span>F</span>
                  <input class="numericvalue" type="number" :step="1" :min="0" :max="surveyData.company_nb_teachers_F" :value="surveyData['company_nb_teachers__' + cunArea.id + '__' + role.id + '__F']" @input="setTextAsInteger('company_nb_teachers__' + cunArea.id + '__' + role.id + '__F', $event)">
                </label>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      <div class="question">
          <span class="question-title">Distribuzione docenti Grade A tra Fields of Research & Development secondo la classificazione
She Figures:</span>

        <div class="number-row" v-for="sheFiguresArea in sheFiguresAreas" :key="sheFiguresArea.id">
          <div class="number-row-question">
            <span>{{ sheFiguresArea.name }}</span>
          </div>
          <div class="number-row-answer">
            <div class="number-row-answer-male">
              <label>
                <span>M</span>
                <input class="numericvalue" type="number" :step="1" :min="0" :max="surveyData.company_nb_teachers__grade_a__M" :value="surveyData['company_university_nb_grade_a__' + sheFiguresArea.id + '__M']" @input="setTextAsInteger('company_university_nb_grade_a__' + sheFiguresArea.id + '__M', $event)">
              </label>
            </div>
            <div class="number-row-answer-female">
              <label>
                <span>F</span>
                <input class="numericvalue" type="number" :step="1" :min="0" :max="surveyData.company_nb_teachers__grade_a__F" :value="surveyData['company_university_nb_grade_a__' + sheFiguresArea.id + '__F']" @input="setTextAsInteger('company_university_nb_grade_a__' + sheFiguresArea.id + '__F', $event)">
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="question">
        <span class="question-title">Regime di impiego:</span>

        <div class="number-row" v-for="role in [
            { id: 'full_time', name: 'Tempo pieno'},
            { id: 'part_time', name: 'Tempo definito'},
          ]" :key="role.id">
          <div class="number-row-question">
            <span>{{ role.name }}</span>
          </div>
          <div class="number-row-answer">
            <div class="number-row-answer-male">
              <label>
                <span>M</span>
                <input class="numericvalue" type="number" :step="1" :min="0" :max="surveyData.company_nb_teachers_M" :value="surveyData['company_university_nb__' + role.id + '__M']" @input="setTextAsInteger('company_university_nb__' + role.id + '__M', $event)">
              </label>
            </div>
            <div class="number-row-answer-female">
              <label>
                <span>F</span>
                <input class="numericvalue" type="number" :step="1" :min="0" :max="surveyData.company_nb_teachers_F" :value="surveyData['company_university_nb__' + role.id + '__F']" @input="setTextAsInteger('company_university_nb__' + role.id + '__F', $event)">
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="question">
        <span class="question-title">Anno sabbatico</span>

        <div class="number-row">
          <div class="number-row-question">
            <span>Docenti in anno sabbatico</span>
          </div>
          <div class="number-row-answer">
            <div class="number-row-answer-male">
              <label>
                <span>M</span>
                <input class="numericvalue" type="number" :step="1" :min="0" :max="surveyData.company_nb_teachers_M" :value="surveyData['company_university_nb__gap-year__M']" @input="setTextAsInteger('company_university_nb__gap-year__M', $event)">
              </label>
            </div>
            <div class="number-row-answer-female">
              <label>
                <span>F</span>
                <input class="numericvalue" type="number" :step="1" :min="0" :max="surveyData.company_nb_teachers_F" :value="surveyData['company_university_nb__gap-year__F']" @input="setTextAsInteger('company_university_nb__gap-year__F', $event)">
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="question">
        <span class="question-title">Composizione delle commissioni secondo D.lgs. 165/2001:</span>

        <div class="number-row">
          <div class="number-row-question">
            <span>Commissioni che rispettano la regola</span>
          </div>
          <div class="number-row-answer">
            <div>
              <label>
                <span></span>
                <input class="numericvalue" type="number" :step="1" :min="0" :value="surveyData.company_university_nb_commissions_ok" @input="setTextAsInteger('company_university_nb_commissions_ok', $event)">
              </label>
            </div>
          </div>
        </div>
        <div class="number-row">
          <div class="number-row-question">
            <span>Commissioni che non rispettano la regola</span>
          </div>
          <div class="number-row-answer">
            <div>
              <label>
                <span></span>
                <input class="numericvalue" type="number" :step="1" :min="0" :value="surveyData.company_university_nb_commissions_ko" @input="setTextAsInteger('company_university_nb_commissions_ko', $event)">
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="question">
        <span class="question-title">Relatori/trici di tesi</span>

        <div class="number-row">
          <div class="number-row-question">
            <span>Docenti relatori/trici di tesi</span>
          </div>
          <div class="number-row-answer">
            <div class="number-row-answer-male">
              <label>
                <span>M</span>
                <input class="numericvalue" type="number" :step="1" :min="0" :value="surveyData['company_university_nb__thesis-supervisor__M']" @input="setTextAsInteger('company_university_nb__thesis-supervisor__M', $event)">
              </label>
            </div>
            <div class="number-row-answer-female">
              <label>
                <span>F</span>
                <input class="numericvalue" type="number" :step="1" :min="0" :value="surveyData['company_university_nb__thesis-supervisor__F']" @input="setTextAsInteger('company_university_nb__thesis-supervisor__F', $event)">
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="question">
        <span class="question-title">Fruizione dei congedi parentali e permessi L.104/1992 per genere</span>

        <div class="number-row" v-for="role in [
            { id: 'permessi_giornalieri_104', name: 'Numero permessi giornalieri L.104/1992 fruiti'},
            { id: 'permessi_orari_104', name: 'Numero permessi orari L.104/1992 (n.ore) fruiti'},
            { id: 'permessi_giornalieri_congedi_parentali', name: 'Numero permessi giornalieri per congedi parentali fruiti'},
            { id: 'permessi_orari_congedi_parentali', name: 'Numero permessi orari per congedi parentali fruiti'},
          ]" :key="role.id">
          <div class="number-row-question">
            <span>{{ role.name }}</span>
          </div>
          <div class="number-row-answer">
            <div class="number-row-answer-male">
              <label>
                <span>M</span>
                <input class="numericvalue" type="number" :step="1" :min="0" :max="surveyData.company_nb_workers_M" :value="surveyData['company_university_nb_' + role.id + '_M']" @input="setTextAsInteger('company_university_nb_' + role.id + '_M', $event)">
              </label>
            </div>
            <div class="number-row-answer-female">
              <label>
                <span>F</span>
                <input class="numericvalue" type="number" :step="1" :min="0" :max="surveyData.company_nb_workers_F" :value="surveyData['company_university_nb_' + role.id + '_F']" @input="setTextAsInteger('company_university_nb_' + role.id + '_F', $event)">
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="question">
        <span class="question-title">Fruizione della formazione suddiviso per genere</span>

        <table>
          <thead>
          <tr style="vertical-align: top">
            <th style="width: calc(100% - 1250px)">Classe di età</th>
            <th style="width: 250px">Obbligatoria (sicurezza)</th>
            <th style="width: 250px">Aggiornamento professionale</th>
            <th style="width: 250px">Competenze manageriali/Relazionali</th>
            <th style="width: 250px">Tematiche CUG</th>
            <th style="width: 250px">Altro</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="age in [
            { id: 'under_30', name: '&lt; 30 anni' },
            { id: 'under_40', name: 'da 31 a 40 anni' },
            { id: 'under_50', name: 'da 41 a 50 anni' },
            { id: 'under_60', name: 'da 51 a 60 anni' },
            { id: 'over_60', name: '&gt; 60 anni' },
          ]" :key="age.id" style="vertical-align: top">
            <th role="rowheader">
              {{ age.name }}
            </th>

            <td v-for="category in [
              { id: 'training_safety', name: 'Obbligatoria (sicurezza)' },
              { id: 'training_professional_updating', name: 'Aggiornamento professionale' },
              { id: 'training_manager', name: 'Competenze manageriali/Relazionali' },
              { id: 'training_cug', name: 'Tematiche CUG' },
              { id: 'training_other', name: 'Altro', extra: true },
            ]" :key="age.id + '_' + category.id">
              <div class="d-flex">
                <label>
                  <span>M</span>
                  <input class="numericvalue" type="number" :step="1" :min="0" :value="surveyData['company_university__' + category.id + '__' + age.id + '__nb_hours_M']" @input="setTextAsInteger('company_university__' + category.id + '__' + age.id + '__nb_hours_M', $event)">
                </label>
                <label>
                  <span>F</span>
                  <input class="numericvalue" type="number" :step="1" :min="0" :value="surveyData['company_university__' + category.id + '__' + age.id + '__nb_hours_F']" @input="setTextAsInteger('company_university__' + category.id + '__' + age.id + '__nb_hours_F', $event)">
                </label>
              </div>
              <div v-if="category.extra" class="extra">
                <label>Specificare: <input type="text" :value="surveyData['company_university__' + category.id + '__' + age.id + '__what']" @input="setText('company_university__' + category.id + '__' + age.id + '__what', $event)"></label>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </template>

    <template v-if="isUniversita">
      <h3>Personale tecnico-amministrativo anno solare {{ referenceYear }}</h3>

      <div class="question">
        <span class="question-title">Personale per ruolo</span>

        <table>
          <thead>
          <tr>
            <th style="width: calc(100% - 1000px)">Ruolo</th>
            <th style="width: 250px">Totale</th>
            <th style="width: 250px">Assunti</th>
            <th style="width: 250px">Cessati</th>
            <th style="width: 250px"><span v-tooltip.hover="'Solo personale a tempo indeterminato'">Età media</span></th>
          </tr>
          </thead>

          <tbody>
          <tr v-for="category in [
            { id: 'cel', name: 'CEL' },
            { id: 'catB', name: 'Categoria B' },
            { id: 'catC', name: 'Categoria C' },
            { id: 'catD', name: 'Categoria D' },
            { id: 'catEP', name: 'Categoria EP' },
            { id: 'dirigenti', name: 'Dirigenti di 2° fascia' },
            { id: 'dg', name: 'Direttori/trici generali' },
          ]" :key="category.id">
            <th role="rowheader">{{ category.name }}</th>

            <td>
              <div class="d-flex">
                <label>
                  <span>M</span>
                  <input class="numericvalue" type="number" :step="1" :min="0" :value="surveyData['company_nb_uni_pta__role__' + category.id + '__M']" @input="setTextAsInteger('company_nb_uni_pta__role__' + category.id + '__M', $event)">
                </label>
                <label>
                  <span>F</span>
                  <input class="numericvalue" type="number" :step="1" :min="0" :value="surveyData['company_nb_uni_pta__role__' + category.id + '__F']" @input="setTextAsInteger('company_nb_uni_pta__role__' + category.id + '__F', $event)">
                </label>
              </div>
            </td>

            <td>
              <div class="d-flex">
                <label>
                  <span>M</span>
                  <input class="numericvalue" type="number" :step="1" :min="0" :value="surveyData['company_nb_uni_pta__hired__' + category.id + '__M']" @input="setTextAsInteger('company_nb_uni_pta__hired__' + category.id + '__M', $event)">
                </label>
                <label>
                  <span>F</span>
                  <input class="numericvalue" type="number" :step="1" :min="0" :value="surveyData['company_nb_uni_pta__hired__' + category.id + '__F']" @input="setTextAsInteger('company_nb_uni_pta__hired__' + category.id + '__F', $event)">
                </label>
              </div>
            </td>

            <td>
              <div class="d-flex">
                <label>
                  <span>M</span>
                  <input class="numericvalue" type="number" :step="1" :min="0" :value="surveyData['company_nb_uni_pta__terminated__' + category.id + '__M']" @input="setTextAsInteger('company_nb_uni_pta__terminated__' + category.id + '__M', $event)">
                </label>
                <label>
                  <span>F</span>
                  <input class="numericvalue" type="number" :step="1" :min="0" :value="surveyData['company_nb_uni_pta__terminated__' + category.id + '__F']" @input="setTextAsInteger('company_nb_uni_pta__terminated__' + category.id + '__F', $event)">
                </label>
              </div>
            </td>

            <td>
              <div class="d-flex">
                <label>
                  <span>M</span>
                  <input class="numericvalue" type="number" :step=".1" :min="0" :value="surveyData['company_avg_uni_pta__age__role__' + category.id + '__M']" @input="setTextAsFloat('company_avg_uni_pta__age__role__' + category.id + '__M', $event)">
                </label>
                <label>
                  <span>F</span>
                  <input class="numericvalue" type="number" :step=".1" :min="0" :value="surveyData['company_avg_uni_pta__age__role__' + category.id + '__F']" @input="setTextAsFloat('company_avg_uni_pta__age__role__' + category.id + '__F', $event)">
                </label>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      <div class="question">
        <span class="question-title">Distribuzione per genere, anzianità lavorativa e ruolo:</span>

        <table>
          <thead>
          <tr>
            <th>Ruolo</th>
            <th style="width: 250px">&lt; 6 anni</th>
            <th style="width: 250px">da 6 a 10 anni</th>
            <th style="width: 250px">da 11 a 20 anni</th>
            <th style="width: 250px">da 21 a 30 anni</th>
            <th style="width: 250px">&gt; 30 anni</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="category in [
            { id: 'cel', name: 'CEL' },
            { id: 'catB', name: 'Categoria B' },
            { id: 'catC', name: 'Categoria C' },
            { id: 'catD', name: 'Categoria D' },
            { id: 'catEP', name: 'Categoria EP' },
            { id: 'dirigenti', name: 'Dirigenti di 2° fascia' },
            { id: 'dg', name: 'Direttori/trici generali' },
          ]" :key="category.id">
            <th role="rowheader">{{ category.name }}</th>

            <td v-for="age in [
            { id: 'lt6', name: '(&lt; 6 anni di anzianità lavorativa)' },
            { id: '6_10', name: '(6-10 anni di anzianità lavorativa)' },
            { id: '11_20', name: '(11-20 anni di anzianità lavorativa)' },
            { id: '21_30', name: '(21-30 anni di anzianità lavorativa)' },
            { id: 'gt30', name: '(&gt; 30 anni di anzianità lavorativa)' },
          ]" :key="age.id + '_' + category.id">
              <div class="d-flex">
                <label>
                  <span>M</span>
                  <input class="numericvalue" type="number" :step="1" :min="0" :value="surveyData['company_nb_uni_pta__seniority__' + age.id + '__' + category.id + '__M']" @input="setTextAsInteger('company_nb_uni_pta__age__' + age.id + '__' + category.id + '__M', $event)">
                </label>
                <label>
                  <span>F</span>
                  <input class="numericvalue" type="number" :step="1" :min="0" :value="surveyData['company_nb_uni_pta__seniority__' + age.id + '__' + category.id + '__F']" @input="setTextAsInteger('company_nb_uni_pta__age__' + age.id + '__' + category.id + '__F', $event)">
                </label>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      <div class="question">
        <span class="question-title">Distribuzione per genere e area:</span>

        <div class="number-row" v-for="category in [
            { id: 'dirigenza', name: 'Dirigenza amministrativa' },
            { id: 'amministrazione', name: 'Amministrazione e amministrativa-gestionale' },
            { id: 'biblioteche', name: 'Biblioteche' },
            { id: 'servizi', name: 'Servizi generali e tecnici' },
            { id: 'sociosanitaria', name: 'Socio-sanitaria, medico-odontoiatrica e socio sanitaria' },
            { id: 'tecnica', name: 'Tecnica, tecnico-scientifica ed elaborazione dati' },
            { id: 'unknown', name: 'Area non individuata' },
          ]" :key="category.id">
          <div class="number-row-question">
            <span>{{ category.name }}</span>
          </div>
          <div class="number-row-answer">
            <div class="number-row-answer-male">
              <label>
                <span>M</span>
                <input class="numericvalue" type="number" :step="1" :min="0" :value="surveyData['company_nb_uni_pta__area__' + category.id + '__M']" @input="setTextAsInteger('company_nb_uni_pta__area__' + category.id + '__M', $event)">
              </label>
            </div>
            <div class="number-row-answer-female">
              <label>
                <span>F</span>
                <input class="numericvalue" type="number" :step="1" :min="0" :value="surveyData['company_nb_uni_pta__area__' + category.id + '__F']" @input="setTextAsInteger('company_nb_uni_pta__area__' + category.id + '__F', $event)">
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="question">
        <span class="question-title">Distribuzione per genere ed età (solo personale a tempo indeterminato):</span>

        <div class="number-row" v-for="category in [
            { id: 'lt35', name: '&lt; 35' },
            { id: '35_44', name: '35-44' },
            { id: '45_54', name: '45-54' },
            { id: 'gt54', name: '&gt; 54' },
          ]" :key="category.id">
          <div class="number-row-question">
            <span>{{ category.name }}</span>
          </div>
          <div class="number-row-answer">
            <div class="number-row-answer-male">
              <label>
                <span>M</span>
                <input class="numericvalue" type="number" :step="1" :min="0" :value="surveyData['company_nb_uni_pta__age__' + category.id + '__M']" @input="setTextAsInteger('company_nb_uni_pta__age__' + category.id + '__M', $event)">
              </label>
            </div>
            <div class="number-row-answer-female">
              <label>
                <span>F</span>
                <input class="numericvalue" type="number" :step="1" :min="0" :value="surveyData['company_nb_uni_pta__age__' + category.id + '__F']" @input="setTextAsInteger('company_nb_uni_pta__age__' + category.id + '__F', $event)">
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="question">
        <span class="question-title">Distribuzione per genere e titolo di studio (altro personale, dirigenti 2° fascia, personale EP, personale non dirigente):</span>

        <div class="number-row" v-for="category in [
            { id: 'obbligo', name: 'Scuola dell\'obbligo' },
            { id: 'diploma', name: 'Licenza media superiore di secondo grado' },
            { id: 'triennale', name: 'Laurea triennale' },
            { id: 'magistrale', name: 'Laurea magistrale/specialistica' },
            { id: 'post', name: 'Alta formazione post laurea' },
            { id: 'other', name: 'Altri titoli post laurea' },
          ]" :key="category.id">
          <div class="number-row-question">
            <span>{{ category.name }}</span>
          </div>
          <div class="number-row-answer">
            <div class="number-row-answer-male">
              <label>
                <span>M</span>
                <input class="numericvalue" type="number" :step="1" :min="0" :value="surveyData['company_nb_uni_pta__qualification__' + category.id + '__M']" @input="setTextAsInteger('company_nb_uni_pta__qualification__' + category.id + '__M', $event)">
              </label>
            </div>
            <div class="number-row-answer-female">
              <label>
                <span>F</span>
                <input class="numericvalue" type="number" :step="1" :min="0" :value="surveyData['company_nb_uni_pta__qualification__' + category.id + '__F']" @input="setTextAsInteger('company_nb_uni_pta__qualification__' + category.id + '__F', $event)">
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="question">
        <span class="question-title">Distribuzione per genere e tipologia contrattuale:</span>

        <div class="number-row" v-for="category in [
            { id: 'determinato', name: 'Tempo determinato' },
            { id: 'indeterminato', name: 'Tempo indeterminato' },
          ]" :key="category.id">
          <div class="number-row-question">
            <span>{{ category.name }}</span>
          </div>
          <div class="number-row-answer">
            <div class="number-row-answer-male">
              <label>
                <span>M</span>
                <input class="numericvalue" type="number" :step="1" :min="0" :value="surveyData['company_nb_uni_pta__contract__' + category.id + '__M']" @input="setTextAsInteger('company_nb_uni_pta__contract__' + category.id + '__M', $event)">
              </label>
            </div>
            <div class="number-row-answer-female">
              <label>
                <span>F</span>
                <input class="numericvalue" type="number" :step="1" :min="0" :value="surveyData['company_nb_uni_pta__contract__' + category.id + '__F']" @input="setTextAsInteger('company_nb_uni_pta__contract__' + category.id + '__F', $event)">
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="question">
        <span class="question-title">Distribuzione per genere e regime di impiego:</span>

        <div class="number-row" v-for="category in [
            { id: 'pieno', name: 'Tempo pieno' },
            { id: 'part_lt_50', name: 'Part time &lt; 50%' },
            { id: 'part_gt_50', name: 'Part time &gt; 50%' },
          ]" :key="category.id">
          <div class="number-row-question">
            <span>{{ category.name }}</span>
          </div>
          <div class="number-row-answer">
            <div class="number-row-answer-male">
              <label>
                <span>M</span>
                <input class="numericvalue" type="number" :step="1" :min="0" :value="surveyData['company_nb_uni_pta__working_hours__' + category.id + '__M']" @input="setTextAsInteger('company_nb_uni_pta__working-hours__' + category.id + '__M', $event)">
              </label>
            </div>
            <div class="number-row-answer-female">
              <label>
                <span>F</span>
                <input class="numericvalue" type="number" :step="1" :min="0" :value="surveyData['company_nb_uni_pta__working_hours__' + category.id + '__F']" @input="setTextAsInteger('company_nb_uni_pta__working-hours__' + category.id + '__F', $event)">
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="question">
        <span class="question-title">Giorni di assenza per genere e causa dell'assenza (solo indeterminati):</span>

        <div class="number-row" v-for="category in [
            { id: 'm04', name: 'M04 - Assenza retribuita per malattia' },
            { id: 'o10', name: 'O10 - Congedi retribuiti ai senti dell\'Art. 42 comma 5 D.Lgs. 151/2001' },
            { id: 'pr4', name: 'PR4 - Legge 104/92' },
            { id: 'pr5', name: 'PR5 - Assenza retribuita per maternità o congedo parentale per malattia del figlio' },
            { id: 'pr6', name: 'PR6 - Altri permessi ed assenze retribuite' },
            { id: 's52', name: 'S52 - Altre assenze non retribuite' },
          ]" :key="category.id">
          <div class="number-row-question">
            <span>{{ category.name }}</span>
          </div>
          <div class="number-row-answer">
            <div class="number-row-answer-male">
              <label>
                <span>M</span>
                <input class="numericvalue" type="number" :step="1" :min="0" :value="surveyData['company_nb_uni_pta__hours-cause__' + category.id + '__M']" @input="setTextAsInteger('company_nb_uni_pta__hours-cause__' + category.id + '__M', $event)">
              </label>
            </div>
            <div class="number-row-answer-female">
              <label>
                <span>F</span>
                <input class="numericvalue" type="number" :step="1" :min="0" :value="surveyData['company_nb_uni_pta__hours-cause__' + category.id + '__F']" @input="setTextAsInteger('company_nb_uni_pta__hours-cause__' + category.id + '__F', $event)">
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="question">
        <span class="question-title">Distribuzione posizioni di responsabilità remunerate (Cat. B/C/D) per genere:</span>

        <div class="number-row" v-for="category in [
            { id: 'settore', name: 'Settore/Servizio' },
            { id: 'ufficio', name: 'Ufficio/Unità' },
          ]" :key="category.id">
          <div class="number-row-question">
            <span>{{ category.name }}</span>
          </div>
          <div class="number-row-answer">
            <div class="number-row-answer-male">
              <label>
                <span>M</span>
                <input class="numericvalue" type="number" :step="1" :min="0" :value="surveyData['company_nb_uni_pta__allowance__' + category.id + '__M']" @input="setTextAsInteger('company_nb_uni_pta__allowance__' + category.id + '__M', $event)">
              </label>
            </div>
            <div class="number-row-answer-female">
              <label>
                <span>F</span>
                <input class="numericvalue" type="number" :step="1" :min="0" :value="surveyData['company_nb_uni_pta__allowance__' + category.id + '__F']" @input="setTextAsInteger('company_nb_uni_pta__allowance__' + category.id + '__F', $event)">
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="question">
        <span class="question-title">Distribuzione per importo indennità di posizione (EP) per genere:</span>

        <div class="number-row" v-for="category in [
            { id: 'lt_4k', name: 'Fino a 4.000€' },
            { id: '4k_5k', name: '4.001-5.000€' },
            { id: '5k_6k', name: '5.001-6.000€' },
            { id: 'gt_6k', name: 'Oltre 6.000€' },
          ]" :key="category.id">
          <div class="number-row-question">
            <span>{{ category.name }}</span>
          </div>
          <div class="number-row-answer">
            <div class="number-row-answer-male">
              <label>
                <span>M</span>
                <input class="numericvalue" type="number" :step="1" :min="0" :value="surveyData['company_nb_uni_pta__bonus__' + category.id + '__M']" @input="setTextAsInteger('company_nb_uni_pta__bonus__' + category.id + '__M', $event)">
              </label>
            </div>
            <div class="number-row-answer-female">
              <label>
                <span>F</span>
                <input class="numericvalue" type="number" :step="1" :min="0" :value="surveyData['company_nb_uni_pta__bonus__' + category.id + '__F']" @input="setTextAsInteger('company_nb_uni_pta__bonus__' + category.id + '__F', $event)">
              </label>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-if="isUniversita">
      <h3>Componente studentesca anno accademico {{ referenceYear }}/{{ (parseInt(referenceYear) + 1) }}</h3>

      <div class="question">
        <table>
          <thead>
          <tr style="vertical-align: top">
            <th>Fields of Education and Training ISCED-F-2013</th>
            <th style="width: 200px">Immatricolati/e</th>
            <th style="width: 200px">Iscritti/e Lauree Triennali</th>
            <th style="width: 200px">Iscritti/e Lauree Specialistiche/Magistrali</th>
            <th style="width: 200px">Iscritti/e Lauree a Ciclo Unico</th>
            <th style="width: 200px">Laureati/e (Tutti i corsi)</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="field in iscedF2013Fields" :key="field.id">
            <th role="rowheader">{{ field.name }}</th>

            <td>
              <div class="d-flex">
                <label>
                  <span>M</span>
                  <input class="numericvalue" type="number" :step="1" :min="0" :value="surveyData['company_nb_uni_teach_immatricolati__' + field.id + '__M']" @input="setTextAsInteger('company_nb_uni_teach_immatricolati__' + field.id + '__M', $event)">
                </label>
                <label>
                  <span>F</span>
                  <input class="numericvalue" type="number" :step="1" :min="0" :value="surveyData['company_nb_uni_teach_immatricolati__' + field.id + '__F']" @input="setTextAsInteger('company_nb_uni_teach_immatricolati__' + field.id + '__F', $event)">
                </label>
              </div>
            </td>

            <td>
              <div class="d-flex">
                <label>
                  <span>M</span>
                  <input class="numericvalue" type="number" :step="1" :min="0" :value="surveyData['company_nb_uni_teach_iscritti_triennale__' + field.id + '__M']" @input="setTextAsInteger('company_nb_uni_teach_iscritti_triennale__' + field.id + '__M', $event)">
                </label>
                <label>
                  <span>F</span>
                  <input class="numericvalue" type="number" :step="1" :min="0" :value="surveyData['company_nb_uni_teach_iscritti_triennale__' + field.id + '__F']" @input="setTextAsInteger('company_nb_uni_teach_iscritti_triennale__' + field.id + '__F', $event)">
                </label>
              </div>
            </td>

            <td>
              <div class="d-flex">
                <label>
                  <span>M</span>
                  <input class="numericvalue" type="number" :step="1" :min="0" :value="surveyData['company_nb_uni_teach_iscritti_magistrale__' + field.id + '__M']" @input="setTextAsInteger('company_nb_uni_teach_iscritti_magistrale__' + field.id + '__M', $event)">
                </label>
                <label>
                  <span>F</span>
                  <input class="numericvalue" type="number" :step="1" :min="0" :value="surveyData['company_nb_uni_teach_iscritti_magistrale__' + field.id + '__F']" @input="setTextAsInteger('company_nb_uni_teach_iscritti_magistrale__' + field.id + '__F', $event)">
                </label>
              </div>
            </td>

            <td>
              <div class="d-flex">
                <label>
                  <span>M</span>
                  <input class="numericvalue" type="number" :step="1" :min="0" :value="surveyData['company_nb_uni_teach_iscritti_ciclo_unico__' + field.id + '__M']" @input="setTextAsInteger('company_nb_uni_teach_iscritti_ciclo_unico__' + field.id + '__M', $event)">
                </label>
                <label>
                  <span>F</span>
                  <input class="numericvalue" type="number" :step="1" :min="0" :value="surveyData['company_nb_uni_teach_iscritti_ciclo_unico__' + field.id + '__F']" @input="setTextAsInteger('company_nb_uni_teach_iscritti_ciclo_unico__' + field.id + '__F', $event)">
                </label>
              </div>
            </td>

            <td>
              <div class="d-flex">
                <label>
                  <span>M</span>
                  <input class="numericvalue" type="number" :step="1" :min="0" :value="surveyData['company_nb_uni_teach_laureati__' + field.id + '__M']" @input="setTextAsInteger('company_nb_uni_teach_laureati__' + field.id + '__M', $event)">
                </label>
                <label>
                  <span>F</span>
                  <input class="numericvalue" type="number" :step="1" :min="0" :value="surveyData['company_nb_uni_teach_laureati__' + field.id + '__F']" @input="setTextAsInteger('company_nb_uni_teach_laureati__' + field.id + '__F', $event)">
                </label>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      <div class="question">
        <span class="question-title">Dati per tipologia di Corso di Laurea:</span>

        <table>
          <thead>
          <tr>
            <th></th>
            <th style="width: 250px">Lauree Triennali</th>
            <th style="width: 250px">Lauree Specialistiche/Magistrali</th>
            <th style="width: 250px">Lauree a Ciclo Unico</th>
            <th style="width: 250px">Lauree Vecchio Ordinamento</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="from in [
            { id: 'regione', name: 'Iscritti/e con provenienza dalla stessa regione' },
            { id: 'fuori_regione', name: 'Iscritti/e con provenienza da fuori regione' },
          ]" :key="from.id">
            <th role="rowheader">{{ from.name }}</th>

            <td v-for="type in [
              { id: 'triennale', name: 'Laurea Triennale' },
              { id: 'specialistica', name: 'Laurea Magistrale/Specialistica' },
              { id: 'ciclo_unico', name: 'Laurea a Ciclo Unico' },
              { id: 'vecchio_ordinamento', name: 'Laurea vecchio ordinamento' },
            ]" :key="type.id + '_' + from.id">
              <div class="d-flex">
                <label>
                  <span>M</span>
                  <input class="numericvalue" type="number" :step="1" :min="0" :value="surveyData['company_nb_uni_teach_iscritti_provenienza__' + type.id + '__' + from.id + '__M']" @input="setTextAsInteger('company_nb_uni_teach_iscritti_provenienza__' + type.id + '_' + from.id + '__M', $event)">
                </label>
                <label>
                  <span>F</span>
                  <input class="numericvalue" type="number" :step="1" :min="0" :value="surveyData['company_nb_uni_teach_iscritti_provenienza__' + type.id + '__' + from.id + '__F']" @input="setTextAsInteger('company_nb_uni_teach_iscritti_provenienza__' + type.id + '_' + from.id + '__F', $event)">
                </label>
              </div>
            </td>
          </tr>

          <tr>
            <th role="rowheader">Tasso di abbandono degli studi al primo anno</th>

            <td v-for="type in [
              { id: 'triennale', name: 'Laurea Triennale' },
              { id: 'specialistica', name: 'Laurea Magistrale/Specialistica' },
              { id: 'ciclo_unico', name: 'Laurea a Ciclo Unico' },
            ]" :key="type.id">
              <div class="d-flex">
                <label>
                  <span>M</span>
                  <input class="numericvalue" type="number" :step=".01" :min="0" :max="100" :value="surveyData['company_nb_uni_teach_abbandono__' + type.id + '__M']" @input="setTextAsFloat('company_nb_uni_teach_abbandono__' + type.id + '__M', $event)">
                </label>
                <label>
                  <span>F</span>
                  <input class="numericvalue" type="number" :step=".01" :min="0" :max="100" :value="surveyData['company_nb_uni_teach_abbandono__' + type.id + '__F']" @input="setTextAsFloat('company_nb_uni_teach_abbandono__' + type.id + '__F', $event)">
                </label>
              </div>
            </td>
            <td></td>
          </tr>

          <tr>
            <th role="rowheader">Laureati/e</th>

            <td v-for="category in [
              { id: 'laureati_lt', name: 'Laureati/e Laurea Triennale' },
              { id: 'laureati_lsm', name: 'Laureati/e Laurea Specialistica/Magistrale' },
              { id: 'laureati_cu', name: 'Laureati/e Ciclo Unico' },
              { id: 'laureati_vo', name: 'Laureati/e Vecchio Ordinamento' },
            ]" :key="category.id">
              <div class="d-flex">
                <label>
                  <span>M</span>
                  <input class="numericvalue" type="number" :step="1" :min="0" :value="surveyData['company_nb_uni_teach__' + category.id + '__M']" @input="setTextAsInteger('company_nb_uni_teach__' + category.id + '__M', $event)">
                </label>
                <label>
                  <span>F</span>
                  <input class="numericvalue" type="number" :step="1" :min="0" :value="surveyData['company_nb_uni_teach__' + category.id + '__F']" @input="setTextAsInteger('company_nb_uni_teach__' + category.id + '__F', $event)">
                </label>
              </div>
            </td>
          </tr>

          <tr>
            <th role="rowheader">Percentuale di laureati/e in corso</th>

            <td v-for="type in [
              { id: 'triennale', name: 'Laurea Triennale' },
              { id: 'specialistica', name: 'Laurea Magistrale/Specialistica' },
              { id: 'ciclo_unico', name: 'Laurea a Ciclo Unico' },
            ]" :key="type.id">
              <div class="d-flex">
                <label>
                  <span>M</span>
                  <input class="numericvalue" type="number" :step=".01" :min="0" :max="100" :value="surveyData['company_nb_uni_teach_laureati_in_corso__' + type.id + '__M']" @input="setTextAsFloat('company_nb_uni_teach_laureati_in_corso__' + type.id + '__M', $event)">
                </label>
                <label>
                  <span>F</span>
                  <input class="numericvalue" type="number" :step=".01" :min="0" :max="100" :value="surveyData['company_nb_uni_teach_laureati_in_corso__' + type.id + '__F']" @input="setTextAsFloat('company_nb_uni_teach_laureati_in_corso__' + type.id + '__F', $event)">
                </label>
              </div>
            </td>
            <td></td>
          </tr>

          <tr>
            <th role="rowheader">Tasso di occupazione a 1 anno dalla laurea</th>

            <td v-for="type in [
              { id: 'triennale', name: 'Laurea Triennale' },
              { id: 'specialistica', name: 'Laurea Magistrale/Specialistica' },
              { id: 'ciclo_unico', name: 'Laurea a Ciclo Unico' },
            ]" :key="type.id">
              <div class="d-flex">
                <label>
                  <span>M</span>
                  <input class="numericvalue" type="number" :step=".01" :min="0" :value="surveyData['company_nb_uni_teach_laureati_occupazione__' + type.id + '_1aa__M']" @input="setTextAsFloat('company_nb_uni_teach_laureati_occupazione__' + type.id + '_1aa__M', $event)">
                </label>
                <label>
                  <span>F</span>
                  <input class="numericvalue" type="number" :step=".01" :min="0" :value="surveyData['company_nb_uni_teach_laureati_occupazione__' + type.id + '_1aa__F']" @input="setTextAsFloat('company_nb_uni_teach_laureati_occupazione__' + type.id + '_1aa__F', $event)">
                </label>
              </div>
            </td>
            <td></td>
          </tr>

          <tr>
            <th role="rowheader">Tasso di occupazione a 5 anni dalla laurea</th>

            <td v-for="type in [
              { id: 'triennale', name: 'Laurea Triennale' },
              { id: 'specialistica', name: 'Laurea Magistrale/Specialistica' },
              { id: 'ciclo_unico', name: 'Laurea a Ciclo Unico' },
            ]" :key="type.id">
              <div class="d-flex">
                <label>
                  <span>M</span>
                  <input class="numericvalue" type="number" :step=".01" :min="0" :value="surveyData['company_nb_uni_teach_laureati_occupazione__' + type.id + '_5aa__M']" @input="setTextAsFloat('company_nb_uni_teach_laureati_occupazione__' + type.id + '_5aa__M', $event)">
                </label>
                <label>
                  <span>F</span>
                  <input class="numericvalue" type="number" :step=".01" :min="0" :value="surveyData['company_nb_uni_teach_laureati_occupazione__' + type.id + '_5aa__F']" @input="setTextAsFloat('company_nb_uni_teach_laureati_occupazione__' + type.id + '_5aa__F', $event)">
                </label>
              </div>
            </td>
            <td></td>
          </tr>

          <tr>
            <th role="rowheader">Retribuzione mensile netta media a 1 anno dalla laurea</th>

            <td v-for="type in [
              { id: 'triennale', name: 'Laurea Triennale' },
              { id: 'specialistica', name: 'Laurea Magistrale/Specialistica' },
              { id: 'ciclo_unico', name: 'Laurea a Ciclo Unico' },
            ]" :key="type.id">
              <div class="d-flex">
                <label>
                  <span>M</span>
                  <input class="numericvalue" type="number" :step=".01" :min="0" :value="surveyData['company_nb_uni_teach_laureati_retribuzione__' + type.id + '_1aa__M']" @input="setTextAsFloat('company_nb_uni_teach_laureati_retribuzione__' + type.id + '_1aa__M', $event)">
                </label>
                <label>
                  <span>F</span>
                  <input class="numericvalue" type="number" :step=".01" :min="0" :value="surveyData['company_nb_uni_teach_laureati_retribuzione__' + type.id + '_1aa__F']" @input="setTextAsFloat('company_nb_uni_teach_laureati_retribuzione__' + type.id + '_1aa__F', $event)">
                </label>
              </div>
            </td>
            <td></td>
          </tr>

          <tr>
            <th role="rowheader">Retribuzione mensile netta media a 5 anni dalla laurea</th>

            <td v-for="type in [
              { id: 'triennale', name: 'Laurea Triennale' },
              { id: 'specialistica', name: 'Laurea Magistrale/Specialistica' },
              { id: 'ciclo_unico', name: 'Laurea a Ciclo Unico' },
            ]" :key="type.id">
              <div class="d-flex">
                <label>
                  <span>M</span>
                  <input class="numericvalue" type="number" :step=".01" :min="0" :value="surveyData['company_nb_uni_teach_laureati_retribuzione__' + type.id + '_5aa__M']" @input="setTextAsFloat('company_nb_uni_teach_laureati_retribuzione__' + type.id + '_5aa__M', $event)">
                </label>
                <label>
                  <span>F</span>
                  <input class="numericvalue" type="number" :step=".01" :min="0" :value="surveyData['company_nb_uni_teach_laureati_retribuzione__' + type.id + '_5aa__F']" @input="setTextAsFloat('company_nb_uni_teach_laureati_retribuzione__' + type.id + '_5aa__F', $event)">
                </label>
              </div>
            </td>
            <td></td>
          </tr>
          </tbody>
        </table>
      </div>

      <div class="question">
        <span class="question-title" v-tooltip.hover="'Numero assoluto di corsi, non percentuale'">Corsi di studio segregati rispetto al genere</span>

        <div class="number-row" v-for="category in [
          { id: 'femminile', name: 'Corsi a prevalenza femminile (&gt;60%)' },
          { id: 'neutro', name: 'Corsi neutri rispetto al genere' },
          { id: 'maschile', name: 'Corsi a prevalenza maschile (&gt;60%)' },
        ]" :key="category.id">
          <div class="number-row-question">
            <span>{{ category.name }}</span>
          </div>
          <div class="number-row-answer">
            <div>
              <label>
                <span></span>
                <input class="numericvalue" type="number" :step="1" :min="0" :value="surveyData['company_nb_uni_teach_corsi_segregati__' + category.id]" @input="setTextAsInteger('company_nb_uni_teach_corsi_segregati__' + category.id, $event)">
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="question">
        <span class="question-title">Studenti/esse in programmi di scambio internazionale:</span>

        <div class="number-row" v-for="scambio in [
          { id: 'entrata', name: 'In entrata' },
          { id: 'uscita', name: 'In uscita' },
        ]" :key="scambio.id">
          <div class="number-row-question">
            <span>{{ scambio.name }}</span>
          </div>
          <div class="number-row-answer">
            <div class="number-row-answer-male">
              <label>
                <span>M</span>
                <input class="numericvalue" type="number" :step="1" :min="0" :value="surveyData['company_nb_uni_teach_scambio__' + scambio.id + '__M']" @input="setTextAsInteger('company_nb_uni_teach_scambio__' + scambio.id + '__M', $event)">
              </label>
            </div>
            <div class="number-row-answer-female">
              <label>
                <span>F</span>
                <input class="numericvalue" type="number" :step="1" :min="0" :value="surveyData['company_nb_uni_teach_scambio__' + scambio.id + '__F']" @input="setTextAsInteger('company_nb_uni_teach_scambio__' + scambio.id + '__F', $event)">
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="question">
        <span class="question-title">Studenti/esse per voto di laurea e genere:</span>

        <div class="number-row" v-for="voto in [
          { id: '66_90', name: 'Tra 66 e 90' },
          { id: '91_100', name: 'Tra 91 e 100' },
          { id: '101_105', name: 'Tra 101 e 105' },
          { id: '106_110', name: 'Tra 106 e 110' },
          { id: '110l', name: '110 e lode' },
          { id: 'np', name: 'Non previsto' },
        ]" :key="voto.id">
          <div class="number-row-question">
            <span>{{ voto.name }}</span>
          </div>
          <div class="number-row-answer">
            <div class="number-row-answer-male">
              <label>
                <span>M</span>
                <input class="numericvalue" type="number" :step="1" :min="0" :value="surveyData['company_nb_uni_teach_voto__' + voto.id + '__M']" @input="setTextAsInteger('company_nb_uni_teach_voto__' + voto.id + '__M', $event)">
              </label>
            </div>
            <div class="number-row-answer-female">
              <label>
                <span>F</span>
                <input class="numericvalue" type="number" :step="1" :min="0" :value="surveyData['company_nb_uni_teach_voto__' + voto.id + '__F']" @input="setTextAsInteger('company_nb_uni_teach_voto__' + voto.id + '__F', $event)">
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="question">
        <span class="question-title">Totale studenti e studentesse che hanno completato il percorso di studi:</span>

        <div class="number-row" v-for="category in [
            { id: 'phd', name: 'Dottori/sse di Ricerca' },
            { id: 'specializzati', name: 'Specializzati/e' },
          ]" :key="category.id">
          <div class="number-row-question">
            <span>{{ category.name }}</span>
          </div>
          <div class="number-row-answer">
            <div class="number-row-answer-male">
              <label>
                <span>M</span>
                <input class="numericvalue" type="number" :step="1" :min="0" :value="surveyData['company_nb_uni_teach__' + category.id + '__M']" @input="setTextAsInteger('company_nb_uni_teach__' + category.id + '__M', $event)">
              </label>
            </div>
            <div class="number-row-answer-female">
              <label>
                <span>F</span>
                <input class="numericvalue" type="number" :step="1" :min="0" :value="surveyData['company_nb_uni_teach__' + category.id + '__F']" @input="setTextAsInteger('company_nb_uni_teach__' + category.id + '__F', $event)">
              </label>
            </div>
          </div>
        </div>
        <div class="number-row">
          <div class="number-row-question">
            <span>Iscritti/e a scuole di specializzazione</span>
          </div>
          <div class="number-row-answer">
            <div class="number-row-answer-male">
              <label>
                <span>M</span>
                <input class="numericvalue" type="number" :step="1" :min="0" :value="surveyData['company_nb_uni_teach_iscritti_specializzazione__M']" @input="setTextAsInteger('company_nb_uni_teach_iscritti_specializzazione__M', $event)">
              </label>
            </div>
            <div class="number-row-answer-female">
              <label>
                <span>F</span>
                <input class="numericvalue" type="number" :step="1" :min="0" :value="surveyData['company_nb_uni_teach_iscritti_specializzazione__F']" @input="setTextAsInteger('company_nb_uni_teach_iscritti_specializzazione__F', $event)">
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="question">
        <span class="question-title">Corsi di dottorato di ricerca per Fields of Education and Training ISCED-F-2013:</span>

        <table>
          <thead>
          <tr>
            <th>Fields of Education and Training ISCED-F-2013</th>
            <th style="width: 250px">Iscritti/e</th>
            <th style="width: 250px">Dottori/esse di Ricerca</th>
            <th style="width: 250px">Tasso di abbandono al primo anno</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="field in iscedF2013Fields" :key="field.id">
            <th role="rowheader">{{ field.name }}</th>

            <td>
              <div class="d-flex">
                <label>
                  <span>M</span>
                  <input class="numericvalue" type="number" :step="1" :min="0" :value="surveyData['company_nb_uni_teach_iscritti_dottorato__' + field.id + '__M']" @input="setTextAsInteger('company_nb_uni_teach_iscritti_dottorato__' + field.id + '__M', $event)">
                </label>
                <label>
                  <span>F</span>
                  <input class="numericvalue" type="number" :step="1" :min="0" :value="surveyData['company_nb_uni_teach_iscritti_dottorato__' + field.id + '__F']" @input="setTextAsInteger('company_nb_uni_teach_iscritti_dottorato__' + field.id + '__F', $event)">
                </label>
              </div>
            </td>

            <td>
              <div class="d-flex">
                <label>
                  <span>M</span>
                  <input class="numericvalue" type="number" :step="1" :min="0" :value="surveyData['company_nb_uni_teach_dottorato__' + field.id + '__M']" @input="setTextAsInteger('company_nb_uni_teach_dottorato__' + field.id + '__M', $event)">
                </label>
                <label>
                  <span>F</span>
                  <input class="numericvalue" type="number" :step="1" :min="0" :value="surveyData['company_nb_uni_teach_dottorato__' + field.id + '__F']" @input="setTextAsInteger('company_nb_uni_teach_dottorato__' + field.id + '__F', $event)">
                </label>
              </div>
            </td>

            <td>
              <div class="d-flex">
                <label>
                  <span>M</span>
                  <input class="numericvalue" type="number" :step="1" :min="0" :max="100" :value="surveyData['company_nb_uni_teach_abbandono_dottorato__' + field.id + '__M']" @input="setTextAsFloat('company_nb_uni_teach_abbandono_dottorato__' + field.id + '__M', $event)">
                </label>
                <label>
                  <span>F</span>
                  <input class="numericvalue" type="number" :step="1" :min="0" :max="100" :value="surveyData['company_nb_uni_teach_abbandono_dottorato__' + field.id + '__F']" @input="setTextAsFloat('company_nb_uni_teach_abbandono_dottorato__' + field.id + '__F', $event)">
                </label>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </template>

    <template v-if="isUniversita">
      <h3>Incarichi istituzionali e di governo</h3>
      <div class="question">
        <span class="question-title">Composizione di genere nella governance di Ateneo:</span>

        <div class="number-row" v-for="category in [
            { id: 'rettore', name: 'Rettore/trice' },
            { id: 'dg', name: 'Direttore/trice generale' },
            { id: 'prorettore_vicario', name: 'Prorettore/trice vicario' },
            { id: 'prorettore', name: 'Prorettori/trici' },
            { id: 'altri_delegati', name: 'Altri/e Delegati/e del/la Rettore/trice' },
            { id: 'senato_accademico', name: 'Senato accademico' },
            { id: 'consiglio_amministrazione', name: 'Consiglio di amministrazione' },
            { id: 'cug', name: 'CUG' },
            { id: 'nucleo_valutazione', name: 'Nucleo di valutazione' },
            { id: 'direttori_dipartimento', name: 'Direttori/trici di dipartimento' },
            { id: 'presidi_scuola', name: 'Presidi di scuola' },
            { id: 'presidenti_cds', name: 'Presidenti CdS' },
            { id: 'coordinatore_scuola_dottorato', name: 'Coordinatori/trici di Scuole di Dottorato' },
            { id: 'direttori_centri_ricerca_ateneo', name: 'Direttori/trice di Centri di Ricerca di Ateneo' },
          ]" :key="category.id">
          <div class="number-row-question">
            <span>{{ category.name }}</span>
          </div>
          <div class="number-row-answer">
            <div class="number-row-answer-male">
              <label>
                <span>M</span>
                <input class="numericvalue" type="number" :step="1" :min="0" :value="surveyData['company_university_role_nb__' + category.id + '__M']" @input="setTextAsInteger('company_university_role_nb__' + category.id + '__M', $event)">
              </label>
            </div>
            <div class="number-row-answer-female">
              <label>
                <span>F</span>
                <input class="numericvalue" type="number" :step="1" :min="0" :value="surveyData['company_university_role_nb__' + category.id + '__F']" @input="setTextAsInteger('company_university_role_nb__' + category.id + '__F', $event)">
              </label>
            </div>
          </div>
        </div>
      </div>
    </template>

    <div class="question" v-if="isNotUniversita">
        <span class="question-title" v-tooltip="'La domanda chiede di indicare il numero complessivo di addetti/e indipendentemente dalla tipologia contrattuale in riferimento all\'anno solare sopraindicato.'">
          Numero di addetti/e per ciascuna fascia di età
        </span>

      <div class="number-row">
        <div class="number-row-question">
          <span>Numero di addetti/e, nell'anno solare {{ referenceYear }}, di età 15-24 anni</span>
        </div>
        <div class="number-row-answer">
          <div class="number-row-answer-male">
            <label>
              <span>M</span>
              <input class="numericvalue" type="number" :step="1" :min="0" :max="surveyData.company_nb_workers_M" :value="surveyData.company_nb_workers_15_24_M" @input="setTextAsInteger('company_nb_workers_15_24_M', $event)">
            </label>
          </div>
          <div class="number-row-answer-female">
            <label>
              <span>F</span>
              <input class="numericvalue" type="number" :step="1" :min="0" :max="surveyData.company_nb_workers_F" :value="surveyData.company_nb_workers_15_24_F" @input="setTextAsInteger('company_nb_workers_15_24_F', $event)">
            </label>
          </div>
        </div>
      </div>
      <div class="number-row">
        <div class="number-row-question">
          <span>Numero di addetti/e, nell'anno solare {{ referenceYear }}, di età 25-34 anni</span>
        </div>
        <div class="number-row-answer">
          <div class="number-row-answer-male">
            <label>
              <span>M</span>
              <input class="numericvalue" type="number" :step="1" :min="0" :max="surveyData.company_nb_workers_M" :value="surveyData.company_nb_workers_25_34_M" @input="setTextAsInteger('company_nb_workers_25_34_M', $event)">
            </label>
          </div>
          <div class="number-row-answer-female">
            <label>
              <span>F</span>
              <input class="numericvalue" type="number" :step="1" :min="0" :max="surveyData.company_nb_workers_F" :value="surveyData.company_nb_workers_25_34_F" @input="setTextAsInteger('company_nb_workers_25_34_F', $event)">
            </label>
          </div>
        </div>
      </div>
      <div class="number-row">
        <div class="number-row-question">
          <span>Numero di addetti/e, nell'anno solare {{ referenceYear }}, di età 35-44 anni</span>
        </div>
        <div class="number-row-answer">
          <div class="number-row-answer-male">
            <label>
              <span>M</span>
              <input class="numericvalue" type="number" :step="1" :min="0" :max="surveyData.company_nb_workers_M" :value="surveyData.company_nb_workers_35_44_M" @input="setTextAsInteger('company_nb_workers_35_44_M', $event)">
            </label>
          </div>
          <div class="number-row-answer-female">
            <label>
              <span>F</span>
              <input class="numericvalue" type="number" :step="1" :min="0" :max="surveyData.company_nb_workers_F" :value="surveyData.company_nb_workers_35_44_F" @input="setTextAsInteger('company_nb_workers_35_44_F', $event)">
            </label>
          </div>
        </div>
      </div>
      <div class="number-row">
        <div class="number-row-question">
          <span>Numero di addetti/e, nell'anno solare {{ referenceYear }}, di età 45-54 anni</span>
        </div>
        <div class="number-row-answer">
          <div class="number-row-answer-male">
            <label>
              <span>M</span>
              <input class="numericvalue" type="number" :step="1" :min="0" :max="surveyData.company_nb_workers_M" :value="surveyData.company_nb_workers_45_54_M" @input="setTextAsInteger('company_nb_workers_45_54_M', $event)">
            </label>
          </div>
          <div class="number-row-answer-female">
            <label>
              <span>F</span>
              <input class="numericvalue" type="number" :step="1" :min="0" :max="surveyData.company_nb_workers_F" :value="surveyData.company_nb_workers_45_54_F" @input="setTextAsInteger('company_nb_workers_45_54_F', $event)">
            </label>
          </div>
        </div>
      </div>
      <div class="number-row">
        <div class="number-row-question">
          <span>Numero di addetti/e, nell'anno solare {{ referenceYear }}, di età > 55 anni</span>
        </div>
        <div class="number-row-answer">
          <div class="number-row-answer-male">
            <label>
              <span>M</span>
              <input class="numericvalue" type="number" :step="1" :min="0" :max="surveyData.company_nb_workers_M" :value="surveyData.company_nb_workers_over55_M" @input="setTextAsInteger('company_nb_workers_over55_M', $event)">
            </label>
          </div>
          <div class="number-row-answer-female">
            <label>
              <span>F</span>
              <input class="numericvalue" type="number" :step="1" :min="0" :max="surveyData.company_nb_workers_F" :value="surveyData.company_nb_workers_over55_F" @input="setTextAsInteger('company_nb_workers_over55_F', $event)">
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="question" v-if="isNotUniversita">
        <span class="question-title" v-tooltip="'La domanda si riferisce al numero di contratti, non al numero di persone con quella tipologia contrattuale.'">
          Tipologia contrattuale del personale nell'anno solare {{ referenceYear }}
        </span>
      <div class="number-row">
        <div class="number-row-question">
          <span>Numero di contratti a tempo indeterminato, nell'anno solare {{ referenceYear }}</span>
        </div>
        <div class="number-row-answer">
          <div class="number-row-answer-male">
            <label>
              <span>M</span>
              <input class="numericvalue" type="number" :step="1" :min="0" :max="surveyData.company_nb_workers_M" :value="surveyData.company_nb_workers_open_ended_M" @input="setTextAsInteger('company_nb_workers_open_ended_M', $event)">
            </label>
          </div>
          <div class="number-row-answer-female">
            <label>
              <span>F</span>
              <input class="numericvalue" type="number" :step="1" :min="0" :max="surveyData.company_nb_workers_F" :value="surveyData.company_nb_workers_open_ended_F" @input="setTextAsInteger('company_nb_workers_open_ended_F', $event)">
            </label>
          </div>
        </div>
      </div>
      <div class="number-row">
        <div class="number-row-question">
          <span>Numero di contratti a tempo determinato, nell'anno solare {{ referenceYear }}</span>
        </div>
        <div class="number-row-answer">
          <div class="number-row-answer-male">
            <label>
              <span>M</span>
              <input class="numericvalue" type="number" :step="1" :min="0" :max="surveyData.company_nb_workers_M" :value="surveyData.company_nb_workers_fixed_term_M" @input="setTextAsInteger('company_nb_workers_fixed_term_M', $event)">
            </label>
          </div>
          <div class="number-row-answer-female">
            <label>
              <span>F</span>
              <input class="numericvalue" type="number" :step="1" :min="0" :max="surveyData.company_nb_workers_F" :value="surveyData.company_nb_workers_fixed_term_F" @input="setTextAsInteger('company_nb_workers_fixed_term_F', $event)">
            </label>
          </div>
        </div>
      </div>
      <div class="number-row">
        <div class="number-row-question">
          <span>Numero di contratti di apprendistato, nell'anno solare {{ referenceYear }}</span>
        </div>
        <div class="number-row-answer">
          <div class="number-row-answer-male">
            <label>
              <span>M</span>
              <input class="numericvalue" type="number" :step="1" :min="0" :max="surveyData.company_nb_workers_M" :value="surveyData.company_nb_workers_apprenticeship_M" @input="setTextAsInteger('company_nb_workers_apprenticeship_M', $event)">
            </label>
          </div>
          <div class="number-row-answer-female">
            <label>
              <span>F</span>
              <input class="numericvalue" type="number" :step="1" :min="0" :max="surveyData.company_nb_workers_F" :value="surveyData.company_nb_workers_apprenticeship_F" @input="setTextAsInteger('company_nb_workers_apprenticeship_F', $event)">
            </label>
          </div>
        </div>
      </div>
      <div class="number-row">
        <div class="number-row-question">
          <span>Numero di contratti formativi, nell'anno solare {{ referenceYear }}</span>
        </div>
        <div class="number-row-answer">
          <div class="number-row-answer-male">
            <label>
              <span>M</span>
              <input class="numericvalue" type="number" :step="1" :min="0" :max="surveyData.company_nb_workers_M" :value="surveyData.company_nb_workers_formative_M" @input="setTextAsInteger('company_nb_workers_formative_M', $event)">
            </label>
          </div>
          <div class="number-row-answer-female">
            <label>
              <span>F</span>
              <input class="numericvalue" type="number" :step="1" :min="0" :max="surveyData.company_nb_workers_F" :value="surveyData.company_nb_workers_formative_F" @input="setTextAsInteger('company_nb_workers_formative_F', $event)">
            </label>
          </div>
        </div>
      </div>
      <div class="number-row">
        <div class="number-row-question">
          <span>Numero di contratti a progetto, nell'anno solare {{ referenceYear }}</span>
        </div>
        <div class="number-row-answer">
          <div class="number-row-answer-male">
            <label>
              <span>M</span>
              <input class="numericvalue" type="number" :step="1" :min="0" :max="surveyData.company_nb_workers_M" :value="surveyData.company_nb_workers_project_focus_M" @input="setTextAsInteger('company_nb_workers_project_focus_M', $event)">
            </label>
          </div>
          <div class="number-row-answer-female">
            <label>
              <span>F</span>
              <input class="numericvalue" type="number" :step="1" :min="0" :max="surveyData.company_nb_workers_F" :value="surveyData.company_nb_workers_project_focus_F" @input="setTextAsInteger('company_nb_workers_project_focus_F', $event)">
            </label>
          </div>
        </div>
      </div>
      <div class="number-row">
        <div class="number-row-question">
          <span>Numero di contratti di collaborazione esterna in regime di partita iva, nell'anno solare {{ referenceYear }}</span>
        </div>
        <div class="number-row-answer">
          <div class="number-row-answer-male">
            <label>
              <span>M</span>
              <input class="numericvalue" type="number" :step="1" :min="0" :max="surveyData.company_nb_workers_M" :value="surveyData.company_nb_workers_independent_contractor_M" @input="setTextAsInteger('company_nb_workers_independent_contractor_M', $event)">
            </label>
          </div>
          <div class="number-row-answer-female">
            <label>
              <span>F</span>
              <input class="numericvalue" type="number" :step="1" :min="0" :max="surveyData.company_nb_workers_F" :value="surveyData.company_nb_workers_independent_contractor_F" @input="setTextAsInteger('company_nb_workers_independent_contractor_F', $event)">
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="question" v-if="isNotUniversita">
      <span class="question-title">Orario di lavoro</span>
      <div class="number-row">
        <div class="number-row-question">
            <span v-tooltip="'Si intende media delle ore lavorate dagli addetti di sesso maschile e femminile.'">
              Totale ore lavorate annualmente nell'anno solare {{ referenceYear }}, solo per il personale con contratto a tempo determinato e indeterminato
            </span>
        </div>
        <div class="number-row-answer">
          <div class="number-row-answer-male">
            <label>
              <span>M</span>
              <input class="numericvalue" type="number" :step="1" :min="0" :value="surveyData.company_nb_work_hours_M" @input="setTextAsInteger('company_nb_work_hours_M', $event)">
            </label>
          </div>
          <div class="number-row-answer-female">
            <label>
              <span>F</span>
              <input class="numericvalue" type="number" :step="1" :min="0" :value="surveyData.company_nb_work_hours_F" @input="setTextAsInteger('company_nb_work_hours_F', $event)">
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="question" v-if="isNotUniversita">
      <span class="question-title">Anzianità media di servizio</span>
      <div class="number-row">
        <div class="number-row-question">
          <span>Anzianità media di servizio degli/lle addetti/e con contratto a tempo indeterminato al 31 dicembre dell'anno {{ referenceYear }}</span>
        </div>
        <div class="number-row-answer">
          <div class="number-row-answer-male">
            <label>
              <span>M</span>
              <input class="numericvalue" type="number" :step=".1" :min="0" :value="surveyData.company_avg_work_age_open_ended_M" @input="setTextAsFloat('company_avg_work_age_open_ended_M', $event)">
            </label>
          </div>
          <div class="number-row-answer-female">
            <label>
              <span>F</span>
              <input class="numericvalue" type="number" :step=".1" :min="0" :value="surveyData.company_avg_work_age_open_ended_F" @input="setTextAsFloat('company_avg_work_age_open_ended_F', $event)">
            </label>
          </div>
        </div>
      </div>
      <div class="number-row">
        <div class="number-row-question">
          <span>Anzianità media di servizio al 31 dicembre dell'anno {{ referenceYear }}, per addetti/e di età 25-34 anni</span>
        </div>
        <div class="number-row-answer">
          <div class="number-row-answer-male">
            <label>
              <span>M</span>
              <input class="numericvalue" type="number" :step=".1" :min="0" :value="surveyData.company_avg_work_age_25_34_M" @input="setTextAsFloat('company_avg_work_age_25_34_M', $event)">
            </label>
          </div>
          <div class="number-row-answer-female">
            <label>
              <span>F</span>
              <input class="numericvalue" type="number" :step=".1" :min="0" :value="surveyData.company_avg_work_age_25_34_F" @input="setTextAsFloat('company_avg_work_age_25_34_F', $event)">
            </label>
          </div>
        </div>
      </div>
      <div class="number-row">
        <div class="number-row-question">
          <span>Anzianità media di servizio al 31 dicembre dell'anno {{ referenceYear }}, per addetti/e di età 35-44 anni</span>
        </div>
        <div class="number-row-answer">
          <div class="number-row-answer-male">
            <label>
              <span>M</span>
              <input class="numericvalue" type="number" :step=".1" :min="0" :value="surveyData.company_avg_work_age_35_44_M" @input="setTextAsFloat('company_avg_work_age_35_44_M', $event)">
            </label>
          </div>
          <div class="number-row-answer-female">
            <label>
              <span>F</span>
              <input class="numericvalue" type="number" :step=".1" :min="0" :value="surveyData.company_avg_work_age_35_44_F" @input="setTextAsFloat('company_avg_work_age_35_44_F', $event)">
            </label>
          </div>
        </div>
      </div>
      <div class="number-row">
        <div class="number-row-question">
          <span>Anzianità media di servizio al 31 dicembre dell'anno {{ referenceYear }}, per addetti/e di età 45-54 anni</span>
        </div>
        <div class="number-row-answer">
          <div class="number-row-answer-male">
            <label>
              <span>M</span>
              <input class="numericvalue" type="number" :step=".1" :min="0" :value="surveyData.company_avg_work_age_45_54_M" @input="setTextAsFloat('company_avg_work_age_45_54_M', $event)">
            </label>
          </div>
          <div class="number-row-answer-female">
            <label>
              <span>F</span>
              <input class="numericvalue" type="number" :step=".1" :min="0" :value="surveyData.company_avg_work_age_45_54_F" @input="setTextAsFloat('company_avg_work_age_45_54_F', $event)">
            </label>
          </div>
        </div>
      </div>
      <div class="number-row">
        <div class="number-row-question">
          <span>Anzianità media di servizio al 31 dicembre dell'anno {{ referenceYear }}, per addetti/e di età > 55 anni</span>
        </div>
        <div class="number-row-answer">
          <div class="number-row-answer-male">
            <label>
              <span>M</span>
              <input class="numericvalue" type="number" :step=".1" :min="0" :value="surveyData.company_avg_work_age_over55_M" @input="setTextAsFloat('company_avg_work_age_over55_M', $event)">
            </label>
          </div>
          <div class="number-row-answer-female">
            <label>
              <span>F</span>
              <input class="numericvalue" type="number" :step=".1" :min="0" :value="surveyData.company_avg_work_age_over55_F" @input="setTextAsFloat('company_avg_work_age_over55_F', $event)">
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="question" v-if="isNotUniversita">
        <span class="question-title" v-tooltip="'Vi preghiamo di indicare il numero di dipendenti appartenenti a ciascuna categoria.'">
          Distribuzione dei dipendenti per categoria nell'anno solare {{ referenceYear }}
        </span>
      <div class="number-row" v-if="isAziendaPrivata || isAziendaPartecipata || isAmministrazioneStatale || isAziendaPubblica">
        <div class="number-row-question">
          <span>Operaio</span>
        </div>
        <div class="number-row-answer">
          <div class="number-row-answer-male">
            <label>
              <span>M</span>
              <input class="numericvalue" type="number" :step="1" :min="0" :max="surveyData.company_nb_workers_M" :value="surveyData.company_nb_workers_labourer_M" @input="setTextAsInteger('company_nb_workers_labourer_M', $event)">
            </label>
          </div>
          <div class="number-row-answer-female">
            <label>
              <span>F</span>
              <input class="numericvalue" type="number" :step="1" :min="0" :max="surveyData.company_nb_workers_F" :value="surveyData.company_nb_workers_labourer_F" @input="setTextAsInteger('company_nb_workers_labourer_F', $event)">
            </label>
          </div>
        </div>
      </div>
      <div class="number-row" v-if="isAziendaPrivata || isAziendaPartecipata || isAmministrazioneStatale || isAziendaPubblica">
        <div class="number-row-question">
          <span>Impiegato</span>
        </div>
        <div class="number-row-answer">
          <div class="number-row-answer-male">
            <label>
              <span>M</span>
              <input class="numericvalue" type="number" :step="1" :min="0" :max="surveyData.company_nb_workers_M" :value="surveyData.company_nb_workers_office_worker_M" @input="setTextAsInteger('company_nb_workers_office_worker_M', $event)">
            </label>
          </div>
          <div class="number-row-answer-female">
            <label>
              <span>F</span>
              <input class="numericvalue" type="number" :step="1" :min="0" :max="surveyData.company_nb_workers_F" :value="surveyData.company_nb_workers_office_worker_F" @input="setTextAsInteger('company_nb_workers_office_worker_F', $event)">
            </label>
          </div>
        </div>
      </div>
      <div class="number-row" v-if="isAziendaPrivata || isAziendaPartecipata || isAmministrazioneStatale || isAziendaPubblica">
        <div class="number-row-question">
          <span>Quadro</span>
        </div>
        <div class="number-row-answer">
          <div class="number-row-answer-male">
            <label>
              <span>M</span>
              <input class="numericvalue" type="number" :step="1" :min="0" :max="surveyData.company_nb_workers_M" :value="surveyData.company_nb_workers_supervisor_M" @input="setTextAsInteger('company_nb_workers_supervisor_M', $event)">
            </label>
          </div>
          <div class="number-row-answer-female">
            <label>
              <span>F</span>
              <input class="numericvalue" type="number" :step="1" :min="0" :max="surveyData.company_nb_workers_F" :value="surveyData.company_nb_workers_supervisor_F" @input="setTextAsInteger('company_nb_workers_supervisor_F', $event)">
            </label>
          </div>
        </div>
      </div>
      <div class="number-row" v-if="isUniversita">
        <div class="number-row-question">
          <span>Personale tecnico-amministrativo dell'area tecnica (contratti B, C, D)</span>
        </div>
        <div class="number-row-answer">
          <div class="number-row-answer-male">
            <label>
              <span>M</span>
              <input class="numericvalue" type="number" :step="1" :min="0" :max="surveyData.company_nb_workers_M" :value="surveyData.company_nb_workers_technical_staff_M" @input="setTextAsInteger('company_nb_workers_technical_staff_M', $event)">
            </label>
          </div>
          <div class="number-row-answer-female">
            <label>
              <span>F</span>
              <input class="numericvalue" type="number" :step="1" :min="0" :max="surveyData.company_nb_workers_F" :value="surveyData.company_nb_workers_technical_staff_F" @input="setTextAsInteger('company_nb_workers_technical_staff_F', $event)">
            </label>
          </div>
        </div>
      </div>
      <div class="number-row" v-if="isUniversita">
        <div class="number-row-question">
          <span>Personale tecnico-amministrativo dell'area amministrativa (contratti B, C, D)</span>
        </div>
        <div class="number-row-answer">
          <div class="number-row-answer-male">
            <label>
              <span>M</span>
              <input class="numericvalue" type="number" :step="1" :min="0" :max="surveyData.company_nb_workers_M" :value="surveyData.company_nb_workers_administrative_staff_M" @input="setTextAsInteger('company_nb_workers_administrative_staff_M', $event)">
            </label>
          </div>
          <div class="number-row-answer-female">
            <label>
              <span>F</span>
              <input class="numericvalue" type="number" :step="1" :min="0" :max="surveyData.company_nb_workers_F" :value="surveyData.company_nb_workers_administrative_staff_F" @input="setTextAsInteger('company_nb_workers_administrative_staff_F', $event)">
            </label>
          </div>
        </div>
      </div>
      <div class="number-row" v-if="isUniversita">
        <div class="number-row-question">
          <span>Dipendente con contratto EP</span>
        </div>
        <div class="number-row-answer">
          <div class="number-row-answer-male">
            <label>
              <span>M</span>
              <input class="numericvalue" type="number" :step="1" :min="0" :max="surveyData.company_nb_workers_M" :value="surveyData.company_nb_workers_specialist_M" @input="setTextAsInteger('company_nb_workers_specialist_M', $event)">
            </label>
          </div>
          <div class="number-row-answer-female">
            <label>
              <span>F</span>
              <input class="numericvalue" type="number" :step="1" :min="0" :max="surveyData.company_nb_workers_F" :value="surveyData.company_nb_workers_specialist_F" @input="setTextAsInteger('company_nb_workers_specialist_F', $event)">
            </label>
          </div>
        </div>
      </div>
      <div class="number-row">
        <div class="number-row-question">
          <span>Dirigente</span>
        </div>
        <div class="number-row-answer">
          <div class="number-row-answer-male">
            <label>
              <span>M</span>
              <input class="numericvalue" type="number" :step="1" :min="0" :max="surveyData.company_nb_workers_M" :value="surveyData.company_nb_workers_manager_M" @input="setTextAsInteger('company_nb_workers_manager_M', $event)">
            </label>
          </div>
          <div class="number-row-answer-female">
            <label>
              <span>F</span>
              <input class="numericvalue" type="number" :step="1" :min="0" :max="surveyData.company_nb_workers_F" :value="surveyData.company_nb_workers_manager_F" @input="setTextAsInteger('company_nb_workers_manager_F', $event)">
            </label>
          </div>
        </div>
      </div>
      <div class="number-row" v-if="isUniversita">
        <div class="number-row-question">
          <span>Ricercatore/trice</span>
        </div>
        <div class="number-row-answer">
          <div class="number-row-answer-male">
            <label>
              <span>M</span>
              <input class="numericvalue" type="number" :step="1" :min="0" :max="surveyData.company_nb_workers_M" :value="surveyData.company_nb_workers_researcher_M" @input="setTextAsInteger('company_nb_workers_researcher_M', $event)">
            </label>
          </div>
          <div class="number-row-answer-female">
            <label>
              <span>F</span>
              <input class="numericvalue" type="number" :step="1" :min="0" :max="surveyData.company_nb_workers_F" :value="surveyData.company_nb_workers_researcher_F" @input="setTextAsInteger('company_nb_workers_researcher_F', $event)">
            </label>
          </div>
        </div>
      </div>
      <div class="number-row" v-if="isUniversita">
        <div class="number-row-question">
          <span>Professore/ssa di seconda fascia</span>
        </div>
        <div class="number-row-answer">
          <div class="number-row-answer-male">
            <label>
              <span>M</span>
              <input class="numericvalue" type="number" :step="1" :min="0" :max="surveyData.company_nb_workers_M" :value="surveyData.company_nb_workers_associate_professor_M" @input="setTextAsInteger('company_nb_workers_associate_professor_M', $event)">
            </label>
          </div>
          <div class="number-row-answer-female">
            <label>
              <span>F</span>
              <input class="numericvalue" type="number" :step="1" :min="0" :max="surveyData.company_nb_workers_F" :value="surveyData.company_nb_workers_associate_professor_F" @input="setTextAsInteger('company_nb_workers_associate_professor_F', $event)">
            </label>
          </div>
        </div>
      </div>
      <div class="number-row" v-if="isUniversita">
        <div class="number-row-question">
            <span v-tooltip="'Si intendono compresi professori/esse ordinari/ie e straordinari/ie.'">
              Professore/ssa di prima fascia
            </span>
        </div>
        <div class="number-row-answer">
          <div class="number-row-answer-male">
            <label>
              <span>M</span>
              <input class="numericvalue" type="number" :step="1" :min="0" :max="surveyData.company_nb_workers_M" :value="surveyData.company_nb_workers_full_professor_M" @input="setTextAsInteger('company_nb_workers_full_professor_M', $event)">
            </label>
          </div>
          <div class="number-row-answer-female">
            <label>
              <span>F</span>
              <input class="numericvalue" type="number" :step="1" :min="0" :max="surveyData.company_nb_workers_F" :value="surveyData.company_nb_workers_full_professor_F" @input="setTextAsInteger('company_nb_workers_full_professor_F', $event)">
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="question" v-if="isNotUniversita">
      <span class="question-title">Regime orario</span>

      <div class="number-row">
        <div class="number-row-question">
          <span>Numero di contratti a tempo determinato in regime FULL-TIME in essere nell'anno solare {{ referenceYear }}</span>
        </div>
        <div class="number-row-answer">
          <div class="number-row-answer-male">
            <label>
              <span>M</span>
              <input class="numericvalue" type="number" :step="1" :min="0" :max="surveyData.company_nb_workers_fixed_term_M" :value="surveyData.company_nb_workers_fixed_term_full_time_M" @input="setTextAsInteger('company_nb_workers_fixed_term_full_time_M', $event)">
            </label>
          </div>
          <div class="number-row-answer-female">
            <label>
              <span>F</span>
              <input class="numericvalue" type="number" :step="1" :min="0" :max="surveyData.company_nb_workers_fixed_term_F" :value="surveyData.company_nb_workers_fixed_term_full_time_F" @input="setTextAsInteger('company_nb_workers_fixed_term_full_time_F', $event)">
            </label>
          </div>
        </div>
      </div>
      <div class="number-row">
        <div class="number-row-question">
          <span>Numero di contratti a tempo indeterminato in regime FULL-TIME in essere nell'anno solare {{ referenceYear }}</span>
        </div>
        <div class="number-row-answer">
          <div class="number-row-answer-male">
            <label>
              <span>M</span>
              <input class="numericvalue" type="number" :step="1" :min="0" :max="surveyData.company_nb_workers_open_ended_M" :value="surveyData.company_nb_workers_open_ended_full_time_M" @input="setTextAsInteger('company_nb_workers_open_ended_full_time_M', $event)">
            </label>
          </div>
          <div class="number-row-answer-female">
            <label>
              <span>F</span>
              <input class="numericvalue" type="number" :step="1" :min="0" :max="surveyData.company_nb_workers_open_ended_F" :value="surveyData.company_nb_workers_open_ended_full_time_F" @input="setTextAsInteger('company_nb_workers_open_ended_full_time_F', $event)">
            </label>
          </div>
        </div>
      </div>
      <div class="number-row">
        <div class="number-row-question">
          <span>Numero di contratti a tempo determinato in regime PART-TIME in essere nell'anno solare {{ referenceYear }}</span>
        </div>
        <div class="number-row-answer">
          <div class="number-row-answer-male">
            <label>
              <span>M</span>
              <input class="numericvalue" type="number" :step="1" :min="0" :max="surveyData.company_nb_workers_fixed_term_M" :value="surveyData.company_nb_workers_fixed_term_part_time_M" @input="setTextAsInteger('company_nb_workers_fixed_term_part_time_M', $event)">
            </label>
          </div>
          <div class="number-row-answer-female">
            <label>
              <span>F</span>
              <input class="numericvalue" type="number" :step="1" :min="0" :max="surveyData.company_nb_workers_fixed_term_F" :value="surveyData.company_nb_workers_fixed_term_part_time_F" @input="setTextAsInteger('company_nb_workers_fixed_term_part_time_F', $event)">
            </label>
          </div>
        </div>
      </div>
      <div class="number-row">
        <div class="number-row-question">
          <span>Numero di contratti a tempo indeterminato in regime PART-TIME in essere nell'anno solare {{ referenceYear }}</span>
        </div>
        <div class="number-row-answer">
          <div class="number-row-answer-male">
            <label>
              <span>M</span>
              <input class="numericvalue" type="number" :step="1" :min="0" :max="surveyData.company_nb_workers_open_ended_M" :value="surveyData.company_nb_workers_open_ended_part_time_M" @input="setTextAsInteger('company_nb_workers_open_ended_part_time_M', $event)">
            </label>
          </div>
          <div class="number-row-answer-female">
            <label>
              <span>F</span>
              <input class="numericvalue" type="number" :step="1" :min="0" :max="surveyData.company_nb_workers_open_ended_F" :value="surveyData.company_nb_workers_open_ended_part_time_F" @input="setTextAsInteger('company_nb_workers_open_ended_part_time_F', $event)">
            </label>
          </div>
        </div>
      </div>
      <div class="number-row">
        <div class="number-row-question">
            <span v-tooltip="'Ci si riferisce al numero di persone e non al numero di contratti'">
              Numero di PART-TIME totali nell'anno solare {{ referenceYear }}
            </span>
        </div>
        <div class="number-row-answer">
          <div class="number-row-answer-male">
            <label>
              <span>M</span>
              <input class="numericvalue" type="number" :step="1" :min="0" :max="surveyData.company_nb_workers_M" :value="surveyData.company_nb_part_time_M" @input="setTextAsInteger('company_nb_part_time_M', $event)">
            </label>
          </div>
          <div class="number-row-answer-female">
            <label>
              <span>F</span>
              <input class="numericvalue" type="number" :step="1" :min="0" :max="surveyData.company_nb_workers_F" :value="surveyData.company_nb_part_time_F" @input="setTextAsInteger('company_nb_part_time_F', $event)">
            </label>
          </div>
        </div>
      </div>
      <div class="number-row">
        <div class="number-row-question">
          <span>Numero di PART-TIME richiesti dal/la lavoratore/trice nell'anno solare {{ referenceYear }}</span>
        </div>
        <div class="number-row-answer">
          <div class="number-row-answer-male">
            <label>
              <span>M</span>
              <input class="numericvalue" type="number" :step="1" :min="0" :max="surveyData.company_nb_part_time_M" :value="surveyData.company_nb_part_time_requested_by_worker_M" @input="setTextAsInteger('company_nb_part_time_requested_by_worker_M', $event)">
            </label>
          </div>
          <div class="number-row-answer-female">
            <label>
              <span>F</span>
              <input class="numericvalue" type="number" :step="1" :min="0" :max="surveyData.company_nb_part_time_F" :value="surveyData.company_nb_part_time_requested_by_worker_F" @input="setTextAsInteger('company_nb_part_time_requested_by_worker_F', $event)">
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="question" v-if="isNotUniversita">
      <span class="question-title">Congedi e permessi</span>

      <div class="number-row" v-if="isAziendaPrivata || isAziendaPartecipata">
        <div class="number-row-question">
          <span>Numero di <span data-explained-by-term @click="showTerm('congedo-di-paternita')">congedi di paternità obbligatori</span> nell'anno solare {{ referenceYear }} (pari ad 1 giorno lavorativo; ex <span data-referring-to-law @click="showLaw('l-92-2012')">L.92/2012</span>)</span>
        </div>
        <div class="number-row-answer">
          <div class="number-row-answer-male">
            <label>
              <span>M</span>
              <input class="numericvalue" type="number" :step="1" :min="0" :max="surveyData.company_nb_workers_M" :value="surveyData.company_nb_leave_paternity_obbligatory_M" @input="setTextAsInteger('company_nb_leave_paternity_obbligatory_M', $event)">
            </label>
          </div>
          <div class="number-row-answer-female">
            &nbsp;
          </div>
        </div>
      </div>
      <div class="number-row" v-if="isAziendaPrivata || isAziendaPartecipata">
        <div class="number-row-question">
          <span>Numero di <span data-explained-by-term @click="showTerm('congedo-di-paternita')">congedi di paternità facoltativi</span> nell'anno solare {{ referenceYear }} (pari al massimo, a due giorni lavorativi; ex <span data-referring-to-law @click="showLaw('l-92-2012')">L.92/2012</span>)</span>
        </div>
        <div class="number-row-answer">
          <div class="number-row-answer-male">
            <label>
              <span>M</span>
              <input class="numericvalue" type="number" :step="1" :min="0" :max="surveyData.company_nb_workers_M" :value="surveyData.company_nb_leave_paternity_optional_M" @input="setTextAsInteger('company_nb_leave_paternity_optional_M', $event)">
            </label>
          </div>
          <div class="number-row-answer-female">
            &nbsp;
          </div>
        </div>
      </div>
      <div class="number-row">
        <div class="number-row-question">
          <span>Numero complessivo di addetti/e che hanno usufruito di <span data-explained-by-term @click="showTerm('congedo-parentale')">congedi parentali</span> (ex astensione facoltativa; ex <span data-referring-to-law @click="showLaw('dlgs-151-2001')">D.Lgs.151/2001</span>) negli ultimi 5 anni</span>
        </div>
        <div class="number-row-answer">
          <div class="number-row-answer-male">
            <label>
              <span>M</span>
              <input class="numericvalue" type="number" :step="1" :min="0" :max="surveyData.company_nb_workers_M" :value="surveyData.company_nb_leave_parental_M" @input="setTextAsInteger('company_nb_leave_parental_M', $event)">
            </label>
          </div>
          <div class="number-row-answer-female">
            <label>
              <span>F</span>
              <input class="numericvalue" type="number" :step="1" :min="0" :max="surveyData.company_nb_workers_F" :value="surveyData.company_nb_leave_parental_F" @input="setTextAsInteger('company_nb_leave_parental_F', $event)">
            </label>
          </div>
        </div>
      </div>
      <div class="number-row">
        <div class="number-row-question">
          <span>Numero medio di giorni di <span data-explained-by-term @click="showTerm('congedo-parentale')">congedo parentale</span> (ex astensione facoltativa; ex <span data-referring-to-law @click="showLaw('dlgs-151-2001')">D.Lgs.151/2001</span>) nella popolazione maschile e femminile negli ultimi 5 anni</span>
        </div>
        <div class="number-row-answer">
          <div class="number-row-answer-male">
            <label>
              <span>M</span>
              <input class="numericvalue" type="number" :min="0" :step=".1" :value="surveyData.company_avg_parental_leave_length_M" @input="setTextAsFloat('company_avg_parental_leave_length_M', $event)">
            </label>
          </div>
          <div class="number-row-answer-female">
            <label>
              <span>F</span>
              <input class="numericvalue" type="number" :min="0" :step=".1" :value="surveyData.company_avg_parental_leave_length_F" @input="setTextAsFloat('company_avg_parental_leave_length_F', $event)">
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="question" v-if="isAziendaPrivata || isAziendaPartecipata">
      <span class="question-title">Cassa integrazione e contratti di solidarietà</span>
      <div class="number-row">
        <div class="number-row-question">
          <span>Se avete fatto ricorso a cassa integrazione (ordinaria, straordinaria, in deroga) o contratti di solidarietà, indicare l'ammontare complessivo del numero di ore/lavoratore e ore/lavoratrice nell'anno solare {{ referenceYear }}</span>
        </div>
        <div class="number-row-answer">
          <div class="number-row-answer-male">
            <label>
              <span>M</span>
              <input class="numericvalue" type="number" :step="1" :min="0" :value="surveyData.company_nb_unemployment_insurance_hours_M" @input="setTextAsInteger('company_nb_unemployment_insurance_hours_M', $event)">
            </label>
          </div>
          <div class="number-row-answer-female">
            <label>
              <span>F</span>
              <input class="numericvalue" type="number" :step="1" :min="0" :value="surveyData.company_nb_unemployment_insurance_hours_F" @input="setTextAsInteger('company_nb_unemployment_insurance_hours_F', $event)">
            </label>
          </div>
        </div>
      </div>
    </div>
    <InfoModal v-if="$modals.terms" modal-class="term-modal" :title="term" @close="closeTerm">
      <div v-html="termText"></div>
    </InfoModal>
    <InfoModal v-if="$modals.laws" modal-class="law-modal" :title="law" @close="closeLaw">
      <h2>{{ lawTitle }}</h2>
      <div v-html="lawText"></div>
    </InfoModal>
  </div>
</template>

<script>

import surveyMixin from '@/mixins/surveyMixin.js';
import stepTitleFilter from '@/filters/stepTitleFilter.js';
import InfoModal from '@/libs/Modals/components/InfoModal.vue';
import { mapGetters } from 'vuex';

export default {
  mixins: [surveyMixin],
  filters: {
    stepTitle: stepTitleFilter,
  },
  components: {
    InfoModal,
  },
  props: {
    edit: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {};
  },
  computed: {
    ...mapGetters('survey', {
      referenceYear: 'referenceYear',
    }),
    canFillUniData () {
      return this.surveyData.company_university && this.referenceYear;
    },
  },
  methods: {
    fillUniData () {
      this.$api.getUniData(this.surveyData.company_university, this.referenceYear)
        .then(res => res.data?.survey || {})
        .then(survey => {
          this.batchUpdateSurveyData(survey);
        })
      ;
    },
  },
};

</script>
